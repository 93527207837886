import React from 'react';
import {graphql} from "gatsby";
import Markdown from "markdown-to-jsx";

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import FormDemoTemplate from "../../components/forms/FormDemoTemplate";

import Col from "../../components/styled/grid/Col";
import {Header2, Header3, Span} from "../../components/styled/typography/Typography";

const DemoForm = ({data}) => {
    
    const {demo} = data
    
    return (
        <Layout lang={demo.locale} seo={demo.SEO}>
            <SectionLayoutGrid customBackground="middle" customPadding={"first"}>
                <Col span={7}>
                    <Header3><Span color="PAS-primary">{demo.title_color}</Span></Header3>
                    <Header2 color="white" marginBottom="normal">
                        {demo.title_white}
                    </Header2>
                    <div className="markdown">
                        <Markdown children={demo.description} options={{forceBlock: true}}/>
                    </div>
                </Col>
                <Col span={5} grid>
                    <FormDemoTemplate
                        locale={demo.locale}
                        list_token={demo.list_token}
                        form_title={demo.form_title}
                        form_button_text={demo.form_button_text}
                    />
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSingleDemoForm($locale: String) {
     demo: strapiDemoForm(locale: {eq: $locale}) {
        locale
        title_color
        title_white
        description
        form_title
        list_token
        form_button_text
        SEO {
             title
             isIndexable
             description
             keywords
             preview {
               url
             }
        }
     }
   }
`

export default DemoForm;